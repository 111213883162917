import React from 'react';
import { AccordionStyle } from './AccordionStyle';

interface AccordionProps {
  className?: string;
  index?: number;
}

class Accordion extends React.PureComponent<AccordionProps> {
  componentDidMount() {
    const acc = document.getElementsByClassName(`accordion-${this.props.index}`);

    for (let i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', () => {
        acc[i].classList.toggle('active');
        const panel = acc[i].nextElementSibling as HTMLElement;
        panel.classList.toggle('expand');
        const active = acc[i].querySelector('.border-bottom');
        active!.classList.toggle('active-border');
      });
    }
  }

  render() {
    const { children, className } = this.props;
    return <AccordionStyle className={className}>{children}</AccordionStyle>;
  }
}

export default Accordion;
