import styled from '@emotion/styled';

interface AccordionItemStyleProps {
  height?: number;
  iconPosition?: 'left' | 'right';
  isClicked?: boolean;
  border?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
}

const AccordionItemStyle = styled.div<AccordionItemStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.8em 0;
  border-top: ${props => props.border?.top};
  border-left: ${props => props.border?.left};
  border-right: ${props => props.border?.right};
  border-bottom: ${props => props.border?.bottom};
  .border-bottom {
    height: 1px;
    width: 100%;
    background-color: rgba(97, 97, 97, 0.36);
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
  }
  .active-border {
    display: none;
  }
  #accordion {
    display: flex;
    justify-content: space-between;
    flex-direction: ${props => (props.iconPosition === 'left' ? 'row-reverse' : 'row')};
    color: #1d2332;
    width: 100%;
    border: none;
    outline: none;
    background: #fff;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;
    span {
      text-align: left;
      font-family: RubikMedium;
    }
  }

  .panel {
    color: #a5a7ad;
    background-color: white;
    display: block;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    &.expand {
      height: 100%;
      max-height: ${props => (props.height ? props.height : 30)}em;
      transition: max-height 0.3s ease-in;
    }
  }
  @media only screen and (max-width: 768px) {
    #accordion {
      padding-right: 2%;
      padding-left: 1%;

      span {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
`;

export { AccordionItemStyle };
