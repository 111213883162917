import React, { FunctionComponent, ReactElement, useState } from 'react';
import { AccordionItemStyle } from './AccordionItemStyle';

interface AccordionItemProps {
  section?: string;
  icon?: {
    shrinkIcon: ReactElement;
    expandIcon: ReactElement;
    position: 'left' | 'right';
  };
  height?: number;
  border?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
  isClicked?: boolean;
  className?: string;
}

const AccordionItem: FunctionComponent<AccordionItemProps> = props => {
  const { icon, section, height, border, children, className } = props;
  const [isClicked, setIsClicked] = useState(false);

  const onClickSection = () => {
    setIsClicked(!isClicked);
  };

  return (
    <AccordionItemStyle iconPosition={icon?.position} height={height} border={border} isClicked={isClicked}>
      <button className={className} id="accordion" type="button" onClick={onClickSection}>
        <span>{section}</span>
        <div className="border-bottom" />
        {isClicked ? icon?.shrinkIcon : icon?.expandIcon}
      </button>
      <div className="panel">{children}</div>
    </AccordionItemStyle>
  );
};

export default AccordionItem;
