/* eslint-disable  max-len */
export const faqStaticData = [
  {
    section: 'A guide to TravelPix',
    data: [
      {
        question: 'What is TravelPix?',
        answer: 'We are a team of professional photographers offering on-demand photography at iconic locations in London without needing to plan, reserve or commit. Our handpicked photographers are located at landmarks around the city, ready to take your photos. Having your photo taken is free and comes with no commitment. Your photos can be viewed online <a target="_blank" href="https://www.travelpix.io/">here</a> or in the <a target="_blank" href="https://travelpix1.app.link">app</a> afterwards. If you like them, and we are confident you will - great - it’s super easy to order. If you don’t - no hard feelings. We know how much love there is out there for the #selfie.'
      },
      {
        question: 'When & where can I find the photographers?',
        answer: 'Check the interactive map to see our current locations and operating times.</br></br><a target="_blank" href="https://travelpix1.app.link/">Interactive map</a>'
      },
      {
        question: 'How good are the photos?',
        answer: 'All our photos are shot with professional DSLR cameras at high-resolution and edited to best capture the moment. Our photographers, handpicked for their expertise, experience and creative skills and equipped with top-notch professional cameras, are what really make the difference and great photos. They know the best angles and poses, can help make you natural and comfortable in front of the camera and will capture your memories better than anyone else could.'
      },
      {
        question: 'How do I view/order my photos?',
        answer: 'To view your photos</br></br>1. Click on the link in the email you received or go directly to our <a target="_blank" href="https://www.travelpix.io/">website</a> and log-in.</br>2. If your photos have already been uploaded, they will automatically appear in “Gallery”</br>3. View & select the photos you want and tap "Purchase your photos" to purchase</br>4. Input your card details, confirm and voilà: click “My photos” to view, save, share via email/ social media or download your photos in high resolution.'
      },
      {
        question: 'How will I know when the photos are ready?',
        answer: 'You will be notified by email and/or push notifications as soon as your photos have been edited, uploaded and are ready to be viewed.'
      },
      {
        question: 'Will my photos be edited?',
        answer: 'Yes. We have a dedicated editing team making adjustments to the photo to ensure colours, contrast, exposure and sharpness are perfect.'
      },
      {
        question: 'How will my photos be delivered?',
        answer: 'Your photos can be viewed directly in our <a target="_blank" href="https://travelpix1.app.link">TravelPix app</a> or on our <a target="_blank" href="https://www.travelpix.io">website</a>. Any photos you purchase can be viewed, shared & downloaded without any watermark. We are working hard on adding the option to order physical delivery. If you are interested, please reach out to us and we will do our best to help</br></br><a href="/contact" target="_blank">Contact Us</a>'
      },
      {
        question: 'How do you choose the photographers?',
        answer: 'Our photographers go through rigorous vetting during the application process. We verify their credentials, existing portfolios and skills to make sure they will take amazing photos and provide a great experience. Quality, experience, ability to direct, creativity and friendliness are what you can expect from all TravelPix photographers.'
      },
      {
        question: 'Do I have to buy the photos before seeing them?',
        answer: 'No, you can view all your photos in the <a target="_blank" href="https://travelpix1.app.link">app</a> or on the <a target="_blank" href="https://www.travelpix.io">website</a> (with a watermark) before making the decision to purchase them.'
      }
    ]
  },
  {
    section: 'Account & Payment Options',
    data: [
      {
        question: 'I can\'t sign in or go online',
        answer: 'There can be several reasons why you can\'t sign in or go online&#58;</br></br>1. Your verification process is not yet completed. Please get in touch if it has been more than a week.</br>2. If everything is in order with your verification process, check that you are logging in with the correct email address and the correct password. A small typo can prevent you from logging in.</br>3. If you have forgotten your password, please try to reset it. We will email you a link to reset your password.</br>If you\'ve checked all of the steps above and still don\'t know why you can\'t log in or go online, please provide more information below. We will be happy to help you.</br></br><a href="/contact" target="_blank">Contact us</a>'
      },
      {
        question: 'How do I add my payment details?',
        answer: 'You can add your card details during the checkout process</br></br>1. Tap "Purchase your photos" once you have selected the photos you like</br>2. Input your card number, expiry date & 3-digit code</br>3. Click "Place order" to confirm your purchase.'
      },
      {
        question: 'How do I pay?',
        answer: 'You can pay all major credit & debit cards. We have partnered with secure payment processing firm Stripe to make sure your payment details are stored safely and securely.</br></br><a target="_blank" href="https://stripe.com/docs/security/stripe">Security at Stripe</a>'
      },
      {
        question: 'Resetting my password',
        answer: 'If you forgot your password or need to reset it, click the link below. Please enter the email address linked to your TravelPix account.</br></br><a target="_blank" href="https://travelpix1.app.link">Reset your password</a>'
      },
      {
        question: 'Delete my TravelPix account',
        answer: 'Let us know that you want to delete your TravelPix account and we will process your request.</br></br>Once your TravelPix account is deleted we won\'t be able to restore it. TravelPix may retain certain information after account deletion as required or permitted by law.'
      },
      {
        question: 'Update my details',
        answer: 'If your details are listed incorrectly on your TravelPix account, you can edit them in the app or contact us to let us know what is incorrect.</br></br>1. Tap Account in the bottom menu.</br>2. Tap My Details > Edit (Pen Icon).</br>3. Enter your updated details and save (green checkmark)'
      }
    ]
  },
  {
    section: 'More',
    data: [
      {
        question: 'Do you offer discounts?',
        answer: 'Yes. Occasionally we offer discounts and promotions when starting new locations or as a thank you to our customers. You can view the promotions in the app.</br></br><a target="_blank" href="https://travelpix1.app.link">Download the app</a> <span class="yellow-bold">to access our discounts and promotions!</span>'
      },
      {
        question: 'Suggesting a new landmark',
        answer: 'We are always looking for great new landmarks. Suggest a new one by emailing us at <a href="mailto:team@travelpix.io" target="_blank">team@travelpix.io</a>'
      },
      {
        question: 'How do you store my photos?',
        answer: 'The photos are stored for 5 days on our servers. If you choose to purchase them during that time, you can download them directly to your phone or computer. After 5 days, they will be securely erased.'
      }
    ]
  },
  {
    section: 'Help & Support',
    data: [
      {
        question: 'Support',
        answer: 'I need something that is not in this list.</br></br><a href="/contact" target="_blank">Contact us</a>'
      },
      {
        question: 'Feedback',
        answer: 'For any suggestions for new features in the app or website send us an email at <a href="mailto:team@travelpix.io" target="_blank">team@travelpix.io</a>'
      },
      {
        question: 'Contact us',
        answer: 'Email: <a href="mailto:team@travelpix.io" target="_blank">team@travelpix.io</a></br>Facebook Chat: TravelPix</br>Whatsapp Chat: +44 chat to us'
      }
    ]
  }
];
