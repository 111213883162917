import styled from '@emotion/styled';
import { css } from '@emotion/core';

const FAQPageStyle = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 90px 1.3rem 0;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  .faq--wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 917px;

    .faq-content {
      height: 100%;
      max-width: 35rem;
      margin-bottom: 125px;

      .faq-title {
        font-size: 60px;
        line-height: 64px;
        color: #fbca00;
        margin: 55px 0 0 0;
        font-family: RubikMedium;
      }
      .faq-section-title {
        font-size: 24px;
        line-height: 28px;
        font-family: RubikMedium;
        padding: 60px 0 0 4px;
      }
      .accordion-content {
        padding: 0.5em;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 1rem;
        color: #a5a7ad;
      }
      .faq-accordion-container {
        max-width: 35em;

        #accordion {
          font-size: 16px;
          line-height: 19px;

          strong {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      a {
        font-size: 13px;
        line-height: 20px;
        font-family: RubikBold;
        color: #fbca00;
      }
      .yellow-bold {
        font-size: 13px;
        line-height: 20px;
        color: #fbca00;
        font-family: RubikBold;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .faq--wrapper {
      .faq-content {
        max-width: 521px;
        transform: translateX(0);
        padding-left: 2%;

        .faq-title {
          font-size: 30px;
          line-height: 34px;
          color: #fbca00;
        }

        .faq-section-title {
          font-size: 21px;
          line-height: 25px;
        }

        .faq-accordion-container {
          padding: 0;
        }
      }
    }
  }
  @media screen and (max-width: 502px) {
    .faq--wrapper {
      .faq-content {
        // max-width: 320px !important;
        margin-bottom: 40px;
      }
    }
  }
`;
const iconAccordionCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbca00;
  border-radius: 50%;
  padding: 0.2em;
  margin-left: 16px;
`;

export { FAQPageStyle, iconAccordionCss };
