import React, { FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Layout from '../../layouts/Layout';
import { FAQPageStyle } from './FAQPageStyle';
import Accordion from '../../components/Accordion';
import AccordionItem from '../../components/Accordion/AccordionItem';
import { faqStaticData } from './faqUtils';
import { Helmet } from 'react-helmet';

const FAQPage: FunctionComponent<{}> = () => {
  return (
    <Layout isFAQPage>
      <FAQPageStyle>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ</title>
        </Helmet>
        <div className="faq--wrapper">
          <div className="faq-content">
            <div className="faq-title">Frequently asked questions</div>
            {faqStaticData.map((item, index) => {
              return (
                <div key={uuidv4()}>
                  <p className="faq-section-title">{item.section}</p>
                  <Accordion
                    className="faq-accordion-container"
                    index={index}>
                    {item.data.map(faq => {
                      return (
                        <AccordionItem
                          key={uuidv4()}
                          className={`accordion-${index}`}
                          section={faq.question}
                          icon={{
                            position: 'right',
                            shrinkIcon: (
                              <div>
                                <img src="/icon/faq_collapse.svg" alt="faq_collapse.svg" />
                              </div>
                            ),
                            expandIcon: (
                              <div>
                                <img src="/icon/faq_expand.svg" alt="faq_expand.svg" />
                              </div>
                            ),
                          }}
                        >
                          <div className="accordion-content" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </div>
              )
            })}
          </div>
        </div>
      </FAQPageStyle>
    </Layout>
  );
};

export default FAQPage;
